import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "@reach/router"
import { UserManager } from "oidc-client"

import { oidcAddUser } from "../state/actions/oidc"

const CallbackPage = ({ addUser }) => {
  useEffect(() => {
    if (typeof window !== `undefined`)
      new UserManager({ response_mode: `query` })
        .signinRedirectCallback()
        .then(user => {
          addUser(user)
          let loginLocation = window.sessionStorage.getItem(`loginLocation`)
          if (loginLocation && loginLocation !== `/klokkefeil`)
            navigate(loginLocation)
          else navigate(`/`)
        })
        .catch(e => {
          console.error(e)
          let error
          typeof e === `string`
            ? (error = e)
            : e.message
            ? (error = e.message)
            : (error = JSON.stringify(e))

          if (error.includes(`the past`) || error.includes(`the future`)) {
            navigate(`/klokkefeil`)
          }

          fetch(`https://www.cloudflare.com/cdn-cgi/trace`)
            .then(res => res.text())
            .then(ipInfo => {
              let ipInfoParts = ipInfo.split(`\n`)

              let country = ipInfoParts
                .find(part => part.startsWith(`loc=`))
                ?.split(`=`)[1]

              let city = ipInfoParts
                .find(part => part.startsWith(`colo=`))
                ?.split(`=`)[1]

              let ip = ipInfoParts
                .find(part => part.startsWith(`ip=`))
                ?.split(`=`)[1]

              let uag = ipInfoParts
                .find(part => part.startsWith(`uag=`))
                ?.split(`=`)[1]

              fetch(`${process.env.ERROR_ENDPOINT}/error`, {
                method: `POST`,
                headers: {
                  "Content-Type": `application/json`,
                  Accept: `application/json`,
                },
                body: JSON.stringify({
                  errorType: `loginerror`,
                  error,
                  location: window.location.href,
                  country,
                  city,
                  ip,
                  uag,
                }),
              })
            })
        })
  }, [])
  return <div>Tar deg til tilbake...</div>
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: user => dispatch(oidcAddUser(user)),
  }
}

export default connect(null, mapDispatchToProps)(CallbackPage)
